import React from "react"
import { StyledTitle } from "../Title"
import "./contactbar.styles.scss"
import { useIntl, Link } from "gatsby-plugin-intl"

export const ContactBar = () => {
  const intl = useIntl()
  return (
    <div className="contactbar_container">
      <StyledTitle
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        {intl.formatMessage({ id: "contactBarTitle" })}
      </StyledTitle>
      <span
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-delay="600"
        data-sal-easing="ease">
        <span
          className="contactbar_button"
        >
          <Link itemProp="url" to="/contact">
            {intl.formatMessage({ id: "contactBarButton" })}
          </Link>
        </span>
      </span>
    </div>
  )
}

export default ContactBar
