import React from "react"
import { StyledTitle } from "../Title"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import "./services.styles.scss"
import Consultanta from "../../../images/Servicii/Consultanta.svg"
import Achizitie from "../../../images/Servicii/Achizitie.svg"
import Instalare from "../../../images/Servicii/Instalare.svg"
import Mentenanta from "../../../images/Servicii/Mentenanta.svg"
import Reparatie from "../../../images/Servicii/Reparatie.svg"
import { useIntl } from "gatsby-plugin-intl"

const getServices = graphql`
  {
    service: file(relativePath: { eq: "servicii-img.webp" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Services = ({ servicesRef }) => {
  const {
    service: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(getServices)

  const intl = useIntl()

  return (
    <section className="services_container" ref={servicesRef}>
      <div className="services_image">
        <Image itemProp="image" fluid={fluid} alt="services-image" />
      </div>
      <div className="services_content">
        <StyledTitle>{intl.formatMessage({ id: "servicesTitle" })}</StyledTitle>
        <ul itemScope itemType="https://schema.org/ItemList" className="services-info_container">
          <li
            itemProp="itemListElement" itemScope itemType="https://schema.org/Product"
            data-sal="fade"
            data-sal-delay={(100 * 1) / 2}
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <img itemProp="image" src={[Consultanta]} alt="service" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span>{intl.formatMessage({ id: "servicesTabs.0.title" })} </span>
              {intl.formatMessage({ id: "servicesTabs.0.subtitle" })}
            </div>
          </li>

          <li
            itemProp="itemListElement" itemScope itemType="https://schema.org/Product"
            data-sal="fade"
            data-sal-delay={(100 * 2) / 2}
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <img itemProp="image" src={[Achizitie]} alt="service" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span>{intl.formatMessage({ id: "servicesTabs.1.title" })} </span>
              {intl.formatMessage({ id: "servicesTabs.1.subtitle" })}
            </div>
          </li>

          <li
            itemProp="itemListElement" itemScope itemType="https://schema.org/Product"
            data-sal="fade"
            data-sal-delay={(100 * 3) / 2}
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <img itemProp="image" src={[Instalare]} alt="service" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span>{intl.formatMessage({ id: "servicesTabs.2.title" })} </span>
              {intl.formatMessage({ id: "servicesTabs.2.subtitle" })}
            </div>
          </li>

          <li
            itemProp="itemListElement" itemScope itemType="https://schema.org/Product"
            data-sal="fade"
            data-sal-delay={(100 * 4) / 2}
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <img itemProp="image" src={[Mentenanta]} alt="service" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span>{intl.formatMessage({ id: "servicesTabs.3.title" })} </span>
              {intl.formatMessage({ id: "servicesTabs.3.subtitle" })}
            </div>
          </li>

          <li
            itemProp="itemListElement" itemScope itemType="https://schema.org/Product"
            data-sal="fade"
            data-sal-delay={(100 * 5) / 2}
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <img itemProp="image" src={[Reparatie]} alt="service" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span>{intl.formatMessage({ id: "servicesTabs.4.title" })} </span>
              {intl.formatMessage({ id: "servicesTabs.4.subtitle" })}
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Services
