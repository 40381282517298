import React from "react"
import "./benefits.styles.scss"
import { StyledTitle } from "../Title"
import { useStaticQuery, graphql } from "gatsby"
import Tab from "./Tab"
import { useIntl } from "gatsby-plugin-intl"
import { isSlug } from "../../../utilities/formaters"

export const Benefits = ({ benefitsRef }) => {
  const {
    tabs: { edges },
  } = useStaticQuery(graphql`
    {
      tabs: allFile(filter: { relativeDirectory: { eq: "TABS" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <section className="benefits_container" ref={benefitsRef}>
      <div className="benefits_content">
        <StyledTitle>
          {intl.formatMessage({ id: "benefitsTitle" })}
        </StyledTitle>
        <div className="benefits-tab_container">
          <Tab
            title={intl.formatMessage({ id: "benefitsTabs.0.title" })}
            content={intl.formatMessage({ id: "benefitsTabs.0.content" })}
            image={edges.filter(edge => {
              return isSlug(
                intl.formatMessage({ id: "benefitsTabs.0.imagePath" }),
                edge.node.childImageSharp.fixed.src
              )
            })[0].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "benefitsTabs.1.title" })}
            content={intl.formatMessage({ id: "benefitsTabs.1.content" })}
            image={edges.filter(edge => {
              return isSlug(
                intl.formatMessage({ id: "benefitsTabs.1.imagePath" }),
                edge.node.childImageSharp.fixed.src
              )
            })[0].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "benefitsTabs.2.title" })}
            content={intl.formatMessage({ id: "benefitsTabs.2.content" })}
            image={edges.filter(edge => {
              return isSlug(
                intl.formatMessage({ id: "benefitsTabs.2.imagePath" }),
                edge.node.childImageSharp.fixed.src
              )
            })[0].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "benefitsTabs.3.title" })}
            content={intl.formatMessage({ id: "benefitsTabs.3.content" })}
            image={edges.filter(edge => {
              return isSlug(
                intl.formatMessage({ id: "benefitsTabs.3.imagePath" }),
                edge.node.childImageSharp.fixed.src
              )
            })[0].node.childImageSharp.fixed}
          />
        </div>
      </div>
    </section>
  )
}

export default Benefits
