import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const MyButton = ({ pdf, className }) => {

  const intl = useIntl()

  if (pdf)
    return (
      <button className={"shadow-effect " + className} >
        <a itemProp="url" href={pdf} target="_blank" rel="noreferrer">
          {intl.formatMessage({ id: "configuratorProductFile" })}
        </a>
      </button>
    )
  else
    return <></>
}

export const StyledButton = styled(MyButton)`
  border: none;
  background: #ffed4d;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  & a {
    text-decoration: none;
    color: #5f1616;
  }
  &:hover {
    box-shadow: initial !important;
    transition: all 100ms ease-in-out;
  }
`
