import React from "react"
import styled from "styled-components"

export const Title = ({ children, className, noTrans }) => {
  if (!noTrans)
    return (
      <h1
        className={className}
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        {children}
      </h1>
    )
  else return <h1
    className={className}>{children}</h1>
}

export const StyledTitleCapriola = styled(Title)`
  font-family: "Work Sans", sans-serif;
  font-size: calc(22px + 12 * ((100vw - 300px) / (2000 - 300)));  
  font-weight: 600;
  color: #2b272b;
  text-align: center;
  margin-bottom: 0.5em;
`
export const StyledTitle = styled(Title)`
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: calc(22px + 12 * ((100vw - 300px) / (2000 - 300)));  
  font-weight: 600;
  color: #2b272b;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  @media (max-width: 800px) {
    font-size: calc(22px + 12 * ((100vw - 300px) / (2000 - 300)));
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
`
