import React from 'react'
import "./client.styles.scss"
import { StyledTitleCapriola } from '../Title'
import PersonCard from "../PersonCard/PersonCard"
import { useIntl } from 'gatsby-plugin-intl'

export const Clients = () => {

  const intl = useIntl()

  const quotes = [
    { text: intl.formatMessage({ id: "clientText1" }), name: "Chiriac Mircea" },
    { text: intl.formatMessage({ id: "clientText2" }), name: "Dan George" },
    { text: intl.formatMessage({ id: "clientText3" }), name: "Rus Sorin" }
  ]
  return (
    <div className="clients_container">
      <div className="top-bar"></div>
      <StyledTitleCapriola><h3 style={{fontFamily:"'Work Sans', sans-serif"}} className="title">{intl.formatMessage({ id: "clientsTitle" })}</h3></StyledTitleCapriola>
      <div className="clients_components">
        {
          quotes.map((q) => {
            return <PersonCard name={q.name} text={q.text}></PersonCard>
          })
        }
      </div>
    </div>
  )
}
export default Clients