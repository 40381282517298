import React from "react"
import Image from "gatsby-image"

export const Tab = ({ title, content, image }) => {
  return (
    <article
      className="benefits-tab_component"
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-duration="1000"
      data-sal-easing="ease"
    >
      <div>
        <Image fixed={image} alt="tab-logo" />
      </div>
      <h2>{title}</h2>
      <p>{content}</p>
    </article>
  )
}

export default Tab