import React from "react"
import "../Benefits/benefits.styles.scss"
import Tab from "../Benefits/Tab"
import { useStaticQuery, graphql } from "gatsby"
import { StyledTitle } from "../Title"
import { useIntl } from "gatsby-plugin-intl"

export const AltServices = () => {
  const {
    fraze: { edges },
  } = useStaticQuery(graphql`
    {
      fraze: allFile(filter: { relativeDirectory: { eq: "Fraze" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 140) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <section className="benefits_container" >
      <div className="benefits_content">
        <StyledTitle>
          {intl.formatMessage({ id: "altServicesTitle" })}
        </StyledTitle>
        <div className="benefits-tab_container">

          <Tab
            title={intl.formatMessage({ id: "altservicesTabs.0.title" })}
            image={edges[0].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "altservicesTabs.1.title" })}
            image={edges[1].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "altservicesTabs.2.title" })}
            image={edges[2].node.childImageSharp.fixed}
          />
          <Tab
            title={intl.formatMessage({ id: "altservicesTabs.3.title" })}
            image={edges[3].node.childImageSharp.fixed}
          />

        </div>
      </div>
    </section>
  )
}

export default AltServices
