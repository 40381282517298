import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import "./personCard.styles.scss"
import Image from "gatsby-image"

const getImages = graphql`
{
  person: file(relativePath: { eq: "man.webp" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  quote: file(relativePath: { eq: "quote.webp" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
const PersonCard = ({ text, name }) => {
  const { person, quote } = useStaticQuery(getImages)

  return (
    <div className="person_card_container">
      <div className="img_div"><Image itemProp="image" className="person_image" fluid={person.childImageSharp.fluid} alt="person-image" /></div>
      <h2 itemProp="author" className="name">{name}</h2>
      <div className="img_div"><Image itemProp="image" className="quote_image" fluid={quote.childImageSharp.fluid} alt="quote-image" /></div>
      <p itemProp="reviewBody" className="quotes_text">{text}</p>
    </div>
  )
}

export default PersonCard