import React, { useState, useCallback } from "react"
import "./configurator.styles.scss"
import { StyledTitle } from "../Title"
import { useStaticQuery, graphql } from "gatsby"
import ConfiguratorRight from "./ConfiguratorRight"
import Image from "gatsby-image"
import { StyledButton } from "../../Echipamente/Products/StyledButton"
import { useIntl, Link } from "gatsby-plugin-intl"

const products = graphql`
  {
    products: allContentfulProduct {
      edges {
        node {
          name
          description
          category {
            secondary,
            primaryRo, 
            primaryEn
          }
          image {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    pdfs: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`

export const Configurator = () => {
  const {
    products: { edges },
    pdfs,
  } = useStaticQuery(products)

  const reseter = () => {
    let newProducts = []
    for (let i = 0; i < pdfs.edges.length; i++) {
      for (let j = 0; j < edges.length; j++) {
        if (
          pdfs.edges[i].node.name.toUpperCase() ===
          edges[j].node.name.split(" ").join``.toUpperCase()
        ) {
          newProducts.push({
            ...edges[j].node,
            file: pdfs.edges[i].node.publicURL,
          })
          break
        }
      }
    }
    return newProducts
  }

  const [productsState, setProducts] = useState(reseter)
  const [suprafataValue, setSuprafataValue] = useState(0)
  const [selectedRadio, setSelectedRadio] = useState({
    cu: false,
    fara: false,
    partiala: false,
  })
  const [selectedType, setSelectedType] = useState({
    apa: false,
    sol: false,
    aer: false,
  })

  const [power, setPower] = useState(null)

  const handleRangeChange = useCallback(e => {
    setSuprafataValue(e.target.value)
  })

  const handleRadioChange = useCallback(e => {
    Object.keys(selectedRadio).forEach(key => {
      selectedRadio[key] = false
    })
    setSelectedRadio({
      ...selectedRadio,
      [e.target.value]: e.target.checked,
    })
  })

  const handleTypeChange = useCallback(e => {
    Object.keys(selectedType).forEach(key => {
      selectedType[key] = false
    })
    setSelectedType({
      ...selectedType,
      [e.target.value]: e.target.checked,
    })
  })

  const [pumpType, setPumpType] = useState("")

  const handleCalculator = () => {
    setProducts([])
    setPower(null)
    let power, type = "", enType = ""

    if (selectedRadio["cu"]) power = suprafataValue * 50
    if (selectedRadio["partiala"]) power = suprafataValue * 75
    if (selectedRadio["fara"]) power = suprafataValue * 100

    if (selectedType["apa"]) { type = "Apa-Apa"; enType = "Water-Water" }
    if (selectedType["sol"]) { type = "Sol-Apa"; enType = "Ground-Water" }
    if (selectedType["aer"]) { type = "Aer-Apa"; enType = "Air-Water" }

    const powerInK = power / 1000
    let first, second, third, fourth, fifth, sixth

    if (selectedType["apa"]) {
      if (powerInK >= 0 && powerInK <= 6) { first = "2-8kW"; second = "6kW"; third = "3-13kW"; fourth = "3~13kW" }
      else if (powerInK >= 6 && powerInK <= 8) { first = "2-8kW"; second = "3~13kW"; third = "3-13kW"; fourth = "8kW" }
      else if (powerInK >= 8 && powerInK <= 10) { first = "2-8kW"; second = "3~13kW"; third = "3-13kW"; fourth = "6-17kW"; fifth = "8kW"; sixth = "10kW" }
      else if (powerInK >= 10 && powerInK <= 13) { first = "3~13kW"; second = "3-13kW"; third = "6-17kW"; fourth = "10kW"; fifth = "13kW" }
      else if (powerInK >= 13 && powerInK <= 17) { first = "3-13kW"; second = "6-17kW"; third = "13kW" }
      else if (powerInK >= 17 && powerInK <= 20) { first = "17kW" }
      else if (powerInK >= 20 && powerInK <= 26) { first = "20kW"; second = "26kW" }
      else if (powerInK >= 26 && powerInK <= 35) { first = "20kW"; second = "26kW" }
      else if (powerInK >= 35 && powerInK <= 42) { first = "26kW"; second = "35kW" }
      else if (powerInK >= 42 && powerInK <= 55) { first = "~35kW"; second = "42kW"; third = "35kW" }
    }

    if (selectedType["sol"]) {
      if (powerInK >= 0 && powerInK <= 6) { first = "6kW"; second = "2-8kW"; third = "3~13"; fourth = "3-13" }
      else if (powerInK >= 6 && powerInK <= 8) { first = "8kW"; second = "2-8kW"; third = "3~13kW"; fourth = "3-13" }
      else if (powerInK >= 8 && powerInK <= 10) { first = "10kW"; second = "3~13kW"; third = "3-13kW"; fourth = "6-17" }
      else if (powerInK >= 10 && powerInK <= 13) { first = "13kW"; second = "3~13kW"; third = "3-13kW"; fourth = "6-17" }
      else if (powerInK >= 13 && powerInK <= 17) { first = "17kW"; second = "6-16kW" }
      else if (powerInK >= 17 && powerInK <= 20) { first = "20kW"; second = "6-17kW" }
      else if (powerInK >= 20 && powerInK <= 26) { first = "26kW"; }
      else if (powerInK >= 26 && powerInK <= 35) { first = "~35kW"; second = "35kW" }
      else if (powerInK >= 35 && powerInK <= 42) { first = "42kW"; second = "50kW" }
      else if (powerInK >= 42 && powerInK <= 55) { first = "55kW"; second = "50kW" }
      else if (powerInK >= 55 && powerInK <= 70) { first = "70kW" }
      else if (powerInK >= 70 && powerInK <= 85) { first = "85kW" }
      else if (powerInK >= 85 && powerInK <= 110) { first = "110kW" }
      else if (powerInK >= 110 && powerInK <= 140) { first = "140kW" }
      else if (powerInK >= 140 && powerInK <= 170) { first = "170kW" }
      else if (powerInK >= 170 && powerInK <= 220) { first = "220kW" }
      else if (powerInK >= 220 && powerInK <= 280) { first = "280kW" }
    }

    if (selectedType["aer"]) {
      if (powerInK >= 0 && powerInK <= 7) { first = "2~7kW"; second = "3~11kW"; third = "2-7kW"; fourth = "3-11kW" }
      else if (powerInK >= 7 && powerInK <= 11) { first = "3~11kW"; second = "4-13kW"; third = "3-11kW" }
      else if (powerInK >= 11 && powerInK <= 13) { first = "4-13kW"; second = "3-11kW"; third = "6-17kW" }
      else if (powerInK >= 13 && powerInK <= 17) { first = "6-17kW"; second = "17kW" }
      else if (powerInK >= 17 && powerInK <= 24) { first = "24kW" }
      else if (powerInK >= 24 && powerInK <= 32) { first = "32kW" }
      else if (powerInK >= 32 && powerInK <= 60) { first = "32kW"; second = "60kW" }
    }

    const newState = []
    for (let i = 0; i < pdfs.edges.length; i++) {
      for (let j = 0; j < edges.length; j++) {
        if (
          pdfs.edges[i].node.name.toUpperCase() ===
          edges[j].node.name.split(" ").join``.toUpperCase()
        ) {
          newState.push({
            ...edges[j].node,
            file: pdfs.edges[i].node.publicURL,
          })
          break
        }
      }
    }

    const firstProduct = first ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === first.split(' ').join``.toLowerCase()) : ''
    const secondProduct = second ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === second.split(' ').join``.toLowerCase()) : ''
    const thirdProduct = third ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === third.split(' ').join``.toLowerCase()) : ''
    const fourthProduct = fourth ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === fourth.split(' ').join``.toLowerCase()) : ''
    const fifthProduct = fifth ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === fifth.split(' ').join``.toLowerCase()) : ''
    const sixthProduct = sixth ? newState.filter(product => product.category.secondary.split(' ').join``.toLowerCase() === sixth.split(' ').join``.toLowerCase()) : ''

    setProducts([firstProduct[0], secondProduct[0], thirdProduct[0], fourthProduct[0], fifthProduct[0], sixthProduct[0]])

    setPower(power)
    if (intl.locale === "en") setPumpType(enType)
    else setPumpType(type)
  }

  const intl = useIntl()

  return (
    <div className="configurator">
      <div className={`configurator_container ${power ? " grow" : ""}`}>
        {power && (
          <div className="configurator_left">
            <StyledTitle noTrans>{intl.formatMessage({ id: "configuratorConsum" })} = {power}W</StyledTitle>
            <div className="configurator_left_products">
              {(productsState[0] !== undefined || productsState[1] !== undefined || productsState[2] !== undefined || productsState[3] !== undefined || productsState[4] !== undefined || productsState[5] !== undefined) ? <h3>{intl.formatMessage({ id: "configuratorRecommendation" })}</h3>
                : pumpType === "" ? " " : <h3>{intl.formatMessage({ id: "configuratorNoRecommendation.0" })}  <Link style={{ color: "#ffffffa1", fontWeight: "bold" }} itemProp="url" to={intl.formatMessage({ id: "navigation.4.path" })}>{intl.formatMessage({ id: "configuratorNoRecommendation.1" })}</Link></h3>
              }

              <div className="configurator_left_products_container">
                {productsState.map((product) => {
                  return (
                    product && <>
                      <div className="configurator_left_products_group">
                        <a style={{ textDecoration: "none" }} itemProp="url" href={product.file} target="_blank" rel="noreferrer">
                          <h6>{product.name}</h6>
                        </a>
                        <a style={{ textDecoration: "none" }} href={product.file} target="_blank" rel="noreferrer">
                          <Image fluid={product.image.fluid} alt={product.name + "-image"} />
                        </a>
                        <div style={{ textAlign: "center" }}>
                          <StyledButton pdf={product.file} />
                        </div>
                      </div>
                    </>)
                }
                )}
              </div>
            </div>
          </div>
        )}
        <ConfiguratorRight
          handleCalculator={handleCalculator}
          handleRadioChange={handleRadioChange}
          handleRangeChange={handleRangeChange}
          handleTypeChange={handleTypeChange}
          selectedRadio={selectedRadio}
          selectedType={selectedType}
          suprafataValue={suprafataValue}
        />
      </div>
    </div>
  )
}
