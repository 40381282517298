import React, { useEffect, useRef } from "react"
import Layout from "../components/Layout/Layout"
import { StyledHeader } from "../components/Header/Header"
import Benefits from "../components/Home/Benefits/Benefits"
import Services from "../components/Home/Services/Services"
import Clients from "../components/Home/Clients/Clients"
import ContactBar from "../components/Home/ContactBar/ContactBar"
import { graphql } from "gatsby"
import Banner from "../components/Home/HomeHeader/Banner"
import { Configurator } from "../components/Home/Configurator/Configurator"
import SEO from "../components/SEO/SEO"
import AltServices from "../components/Home/AltServices/AltServices"
import { Helmet } from "react-helmet"
import TagManager from "../components/TagManager/TagManager"

export default props => {
  const { data } = props
  if (props.location.state === null) {
    props.location.state = { services: false }
  }
  const servicesRef = useRef(null)
  const benefitsRef = useRef(null)

  useEffect(() => {
    if (props.location.state.services) {
      setTimeout(
        () => {
          servicesRef.current.scrollIntoView(true); window.scrollBy({ left: 0, top: -140, behavior: 'smooth' });
        },
        200
      )
    }
  }, [props.location])

  return (
    <Layout>
      <TagManager />
      <SEO
        title="Pompe de caldura profesionale Oradea"
        description="Alege incalzirea eficienta prin pompe de caldura iDM Austria. Instalatii, foraje, automatizari si sisteme de ventilatie."
        robots="index, follow"
        canonical="https://g4h.ro/home"
        openGraph={{ url: "https://www.g4h.ro/home", type: "website", image: "https://g4h.ro/static/efaf3e54cff1491442342f47dd157102/65e33/img.png", title: "Pompe de caldura profesionale Oradea", description: "Alege incalzirea eficienta prin pompe de caldura iDM Austria. Instalatii, foraje, automatizari si sisteme de ventilatie." }}
      />
      <Helmet>
        <meta name="google-site-verification" content="thBepje__YJLjp8WEMimcTlBwdWez_40_naFTk9sUH8" />
      </ Helmet>

      <StyledHeader image={data.homeheader.childImageSharp.fluid} home={true} alt="Homepage Header" className="header_style">
        <Banner benefitsRef={benefitsRef} />
      </StyledHeader>
      <Benefits benefitsRef={benefitsRef} />
      <Services servicesRef={servicesRef} />
      <AltServices />
      <Configurator />
      <Clients />
      <ContactBar />
    </Layout>
  )
}

export const query = graphql`
  {
    homeheader: file(relativePath: { eq: "hero_prima_pagina.webp" }) {
      childImageSharp { 
        fluid(quality: 100, webpQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`