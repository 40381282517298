import React from "react"
import "./banner.styles.scss"
import { useIntl } from "gatsby-plugin-intl"

export const Banner = ({ benefitsRef }) => {

  const intl = useIntl()

  return (
    <header className="home-banner_container" >
      <div className="home-banner_content">
        <h1>{intl.formatMessage({ id: 'home.test' }).toUpperCase()}</h1>
        <div className="home-banner_sub">
          <div className="home-banner_line"></div>
          <h6>
            <span className="home_yellow">Best work.</span>
            <span className="home_red">Basic standards.</span>
          </h6>
          <div className="home-banner_line"></div>
        </div>
      </div>
      <div className="arrow" onClick={() => { benefitsRef.current.scrollIntoView(true); window.scrollBy({ left: 0, top: -150, behavior: 'smooth' }); }}></div>
    </header>
  )
}

export default Banner
