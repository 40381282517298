import React, { useRef } from "react"
import { useIntl } from "gatsby-plugin-intl"

const ConfiguratorRight = ({ handleRadioChange, handleRangeChange, handleTypeChange, handleCalculator, suprafataValue, selectedRadio, selectedType }) => {
  const radioRef = useRef()
  const radioRef2 = useRef()
  const radioRef3 = useRef()

  const typeRef = useRef()
  const typeRef2 = useRef()
  const typeRef3 = useRef()

  const intl = useIntl()

  return (
    <div className="configurator_right">
      <h2>{intl.formatMessage({ id: "simulatorTitle" })}</h2>
      <div className="configurator_right_content">
        <div className="configurator_right_group first">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "93%" }}>
            <h3 style={{ marginTop: "auto" }}>{intl.formatMessage({ id: "simulatorSurface" })}</h3>
            <input
              className="configurator_input"
              type="text"
              readOnly
              min="100"
              max="1000"
              value={suprafataValue}
            />
          </div>

          <div className="configurator_right_inputs">
            <input
              type="range"
              min="100"
              max="1000"
              step="10"
              required
              value={suprafataValue}
              onChange={handleRangeChange}
            />
          </div>
        </div>

        <div className="configurator_right_group">
          <h3>{intl.formatMessage({ id: "simulatorInsulation" })}</h3>
          <div className="configurator_right_radios">
            <div
              className={`configurator_right_radio-group ${selectedRadio["fara"] ? "active-radio" : ""
                }`}
              onClick={() => radioRef.current.click()}
            >
              <input
                type="radio"
                name="izolatie"
                value="fara"
                ref={radioRef}
                onChange={handleRadioChange}
              />
              <div className="configurator_right_radio-group_content">
                <label
                  className="configurator_right_radio-group_content-info"
                  for="fara"
                >
                  {intl.formatMessage({ id: "withoutInsulation" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                  100W/m<span>2</span>
                </label>
              </div>
            </div>
            <div
              className={`configurator_right_radio-group ${selectedRadio["partiala"] ? "active-radio" : ""
                }`}
              onClick={() => radioRef3.current.click()}
            >
              <input
                type="radio"
                name="izolatie"
                value="partiala"
                ref={radioRef3}
                onChange={handleRadioChange}
              />
              <div className="configurator_right_radio-group_content">
                <label
                  className="configurator_right_radio-group_content-info"
                  for="partiala"
                >
                  {intl.formatMessage({ id: "partialInsulation" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                  75W/m<span>2</span>
                </label>
              </div>
            </div>
            <div
              className={`configurator_right_radio-group ${selectedRadio["cu"] ? "active-radio" : ""
                }`}
              onClick={() => radioRef2.current.click()}
            >
              <input
                type="radio"
                name="izolatie"
                value="cu"
                ref={radioRef2}
                onChange={handleRadioChange}
              />
              <div className={"configurator_right_radio-group_content"}>
                <label
                  className="configurator_right_radio-group_content-info"
                  for="cu"
                >
                  {intl.formatMessage({ id: "withInsulation" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                  50W/m<span>2</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="configurator_right_group">
          <h3>{intl.formatMessage({ id: "simulatorType" })}</h3>
          <div className="configurator_right_radios">
            <div
              className={`configurator_right_radio-group ${selectedType["apa"] ? "active-radio" : ""
                }`}
              onClick={() => typeRef.current.click()}
            >
              <input
                type="checkbox"
                name="type"
                value="apa"
                ref={typeRef}
                onChange={handleTypeChange}
              />
              <div className="configurator_right_radio-group_content">
                <label
                  className="configurator_right_radio-group_content-info"
                  for="apa"
                >
                  {intl.formatMessage({ id: "water-water" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                </label>
              </div>
            </div>
            <div
              className={`configurator_right_radio-group ${selectedType["sol"] ? "active-radio" : ""
                }`}
              onClick={() => typeRef2.current.click()}
            >
              <input
                type="checkbox"
                name="type"
                value="sol"
                ref={typeRef2}
                onChange={handleTypeChange}
              />
              <div className="configurator_right_radio-group_content">
                <label
                  className="configurator_right_radio-group_content-info"
                  for="sol"
                >
                  {intl.formatMessage({ id: "ground-water" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                </label>
              </div>
            </div>
            <div
              className={`configurator_right_radio-group ${selectedType["aer"] ? "active-radio" : ""
                }`}
              onClick={() => typeRef3.current.click()}
            >
              <input
                type="checkbox"
                name="type"
                value="aer"
                ref={typeRef3}
                onChange={handleTypeChange}
              />
              <div className={"configurator_right_radio-group_content"}>
                <label
                  className="configurator_right_radio-group_content-info"
                  for="aer"
                >
                  {intl.formatMessage({ id: "air-water" })}
                </label>
                <label className="configurator_right_radio-group_content-info2">
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-div-btn">
          <button onClick={handleCalculator} disabled={suprafataValue === 0 || (!selectedRadio["cu"] && !selectedRadio["fara"] && !selectedRadio["partiala"]) || (!selectedType["apa"] && !selectedType["sol"] && !selectedType["aer"])}>{intl.formatMessage({ id: "simulatorButton" })}</button>
        </div>
      </div>
    </div>
  )
}

export default ConfiguratorRight